@charset "UTF-8";
a.itext {
  width: auto !important;
  height: auto !important;
}

.annot {
  display: none !important;
}

::selection {
  background-color: #d0d0ff;
}

.pcnone {
  display: none;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .pcnone {
    display: block;
  }
}

@media screen and (max-width: 640px) {
  .spnone {
    display: none;
  }
}

@media only screen and (min-width: 834px) and (max-width: 1200px) {
  .bp1200none {
    display: none;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .bp1200none {
    display: block;
  }
}

@media only screen and (max-width: 1200px) {
  .bp1000none {
    display: none;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .bp1000none {
    display: block;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

li {
  list-style: none;
}

html {
  font-size: 62.5% !important;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  letter-spacing: 0.05em;
  font-weight: 400;
  color: #222;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 1.6rem;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.inner {
  margin: 0 auto;
  max-width: 1200px;
}

@media only screen and (min-width: 834px) and (max-width: 1200px) {
  .inner {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (max-width: 834px) {
  .inner {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.inner2 {
  margin-left: auto;
  max-width: 1560px;
  padding-left: 24px;
  padding-right: 24px;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
  max-width: 1200px;
  padding-bottom: 60px;
}

@media only screen and (min-width: 834px) and (max-width: 1200px) {
  #contents {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (max-width: 834px) {
  #contents {
    padding-left: 24px;
    padding-right: 24px;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.tdpd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  color: #17e012;
  transition: 1s;
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  min-width: auto;
}

footer#global_footer {
  min-width: auto;
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  text-align: center;
}

header:nth-of-type(1) {
  background: #fff !important;
}

.pcnone {
  display: none;
}

@media only screen and (min-width: 834px) and (max-width: 900px) {
  .pcnone {
    display: block;
  }
}

@media screen and (max-width: 834px) {
  .pcnone {
    display: block;
  }
}

@media only screen and (min-width: 834px) and (max-width: 900px) {
  .spnone {
    display: none;
  }
}

@media screen and (max-width: 834px) {
  .spnone {
    display: none;
  }
}

@media only screen and (min-width: 834px) and (max-width: 900px) {
  #g-nav {
    /*position:fixed;にし、z-indexの数値を大きくして前面へ*/
    position: fixed;
    z-index: 9999;
    /*ナビのスタート位置と形状*/
    top: -120%;
    left: 0;
    right: 0;
    height: 100vh;
    /*動き*/
    transition: all 0.6s;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /*アクティブクラスがついたら位置を0に*/
  #g-nav.panelactive {
    top: 0;
  }
  #g-nav.panelactive .mainNav {
    display: block;
  }
}

@media screen and (max-width: 834px) {
  #g-nav {
    /*position:fixed;にし、z-indexの数値を大きくして前面へ*/
    position: fixed;
    z-index: 9999;
    /*ナビのスタート位置と形状*/
    top: -120%;
    left: 0;
    right: 0;
    height: 100vh;
    /*動き*/
    transition: all 0.6s;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #g-nav .mainNav {
    display: block !important;
  }
  /*アクティブクラスがついたら位置を0に*/
  #g-nav.panelactive {
    top: 0;
  }
  #g-nav.panelactive .mainNav {
    display: block;
  }
}

#g-nav.panelactive {
  background-color: rgba(161, 180, 26, 0.8);
  padding: 50px;
}

#g-nav.panelactive .mainNav {
  background: #fff !important;
  border-radius: 30px;
  height: fit-content;
  margin: auto;
}

#g-nav {
  position: relative;
  padding: 10px 0;
}

#g-nav .mainNav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

#g-nav .mainNav li {
  position: relative;
}

#g-nav .mainNav li a {
  text-align: center;
  padding: 10px;
  display: block;
  position: relative;
  color: #222;
  font-weight: bold;
}

#g-nav .mainNav li a span {
  display: block;
  font-size: 12px;
  color: #2eb5ce;
  font-family: 'Montserrat', sans-serif;
}

@media screen and (max-width: 834px) {
  #g-nav .mainNav li a {
    display: block;
    padding: 15px;
    font-size: 1.5em;
  }
}

.dropdown {
  width: 250px;
  position: absolute;
  left: 50%;
  top: 70px;
  transform: translateX(-50%);
  z-index: 9999;
}

@media screen and (max-width: 834px) {
  .dropdown {
    position: inherit;
    transform: none;
    top: 0;
    left: 0;
    margin: auto;
  }
}

.dropdown li a {
  background-color: white;
  display: block;
  color: #222 !important;
  border-bottom: 1px solid #ccc;
  padding: 15px !important;
}

@media screen and (max-width: 640px) {
  .dropdown li a {
    border-bottom: none;
  }
}

.toggle {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: -1;
}

@media screen and (max-width: 834px) {
  .toggle {
    z-index: 100;
  }
}

/*ハンバーガーメニュー*/
.openBtn {
  display: none;
}

@media only screen and (min-width: 834px) and (max-width: 900px) {
  .openBtn {
    display: block;
  }
}

@media screen and (max-width: 834px) {
  .openBtn {
    display: block;
  }
}

/*ボタン外側※レイアウトによってpositionや形状は適宜変更してください*/
.openBtn {
  position: fixed;
  /*ボタン内側の基点となるためrelativeを指定*/
  top: 0;
  right: 0;
  cursor: pointer;
  width: 80px;
  height: 80px;
  background: #a1b41a;
  border-radius: 0 0 0 50px;
  box-shadow: -29px 28px 41px -49px #dcdada;
  z-index: 10000;
  padding-bottom: 20px;
}

.btnArea {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/*ボタン内側*/
.openBtn span {
  display: inline-block;
  transition: all .4s;
  /*アニメーションの設定*/
  height: 2px;
  border-radius: 5px;
  background: #fff;
  width: 35%;
}

.openBtn span:nth-of-type(2) {
  margin: 5px 0;
}

/*activeクラスが付与されると線が回転して×になり、Menu⇒Closeに変更*/
.openBtn.active span:nth-of-type(1) {
  top: 14px;
  left: 18px;
  transform: translateY(5px) rotate(-45deg);
  width: 40%;
}

.openBtn.active span:nth-of-type(2) {
  opacity: 0;
}

.openBtn.active span:nth-of-type(3) {
  top: 26px;
  left: 18px;
  transform: translateY(-8px) rotate(45deg);
  width: 40%;
}

@media only screen and (min-width: 834px) and (max-width: 900px) {
  #g-nav {
    /*position:fixed;にし、z-indexの数値を大きくして前面へ*/
    position: fixed;
    z-index: 9999;
    /*ナビのスタート位置と形状*/
    top: -120%;
    left: 0;
    right: 0;
    height: 100vh;
    /*ナビの高さ*/
    /*動き*/
    transition: all 0.6s;
  }
  /*アクティブクラスがついたら位置を0に*/
  #g-nav.panelactive {
    top: 0;
  }
}

@media screen and (max-width: 834px) {
  #g-nav {
    /*position:fixed;にし、z-indexの数値を大きくして前面へ*/
    position: fixed;
    z-index: 9999;
    /*ナビのスタート位置と形状*/
    top: -120%;
    left: 0;
    right: 0;
    height: 100vh;
    /*ナビの高さ*/
    /*動き*/
    transition: all 0.6s;
  }
  /*アクティブクラスがついたら位置を0に*/
  #g-nav.panelactive {
    top: 0;
  }
}

.h-contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: stretch;
  z-index: 9999;
}

@media screen and (max-width: 834px) {
  .h-contact {
    position: fixed;
    left: 0;
    bottom: 0;
  }
}

@media screen and (max-width: 640px) {
  .h-contact {
    width: 100%;
  }
}

@media only screen and (min-width: 834px) and (max-width: 900px) {
  .h-contact {
    position: fixed;
    left: 0;
    bottom: 0;
  }
}

.h-contact .h_tel {
  color: #fff;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25em;
  background-color: #2eb5ce;
  font-family: 'Montserrat', sans-serif;
  width: 238px;
}

@media screen and (max-width: 640px) {
  .h-contact .h_tel {
    width: 100%;
  }
}

.h-contact .h_btn {
  color: #fff;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25em;
  background-color: #a1b41a;
  font-weight: bold;
  width: 238px;
}

.h-contact .h_btn .en {
  display: block;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
}

@media screen and (max-width: 640px) {
  .h-contact .h_btn {
    width: 50%;
  }
}

.h-contact .h_line {
  color: #fff;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25em;
  background-color: #00b900;
  font-family: 'Montserrat', sans-serif;
  width: 238px;
}

@media screen and (max-width: 640px) {
  .h-contact .h_line {
    width: 50%;
  }
}

.h-contact img {
  margin-right: 10px;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  z-index: 9999;
}

.header .h-logo {
  padding: 20px;
}

.mainArea {
  width: calc(100% - 140px);
  margin: 0 auto;
}

@media screen and (max-width: 834px) {
  .mainArea {
    width: calc(100% - 30px);
  }
}

.mainArea img {
  width: 100%;
  border-radius: 80px;
}

.mainWrap {
  position: relative;
}

.mainWrap .mainText {
  font-size: clamp(30px, 6vw, 60px);
  text-align: left;
  font-weight: bold;
  position: absolute;
  bottom: 10%;
  left: 10%;
  line-height: 1.2em;
}

.mainWrap .mainText .blue {
  color: #2eb5ce;
}

.mainWrap .mainText .green {
  color: #a1b41a;
}

.subArea {
  padding: 90px 0;
}

@media screen and (max-width: 834px) {
  .subArea {
    padding: 60px 5%;
  }
}

.subArea h1 {
  text-align: center;
  font-size: clamp(48px, 6vw, 60px);
  font-weight: bold;
  line-height: 1.2em;
  color: #fff;
}

.subArea span {
  display: block;
  font-size: 3rem;
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  text-align: center;
  line-height: 1.2em;
  margin-top: 15px;
}

@media screen and (max-width: 834px) {
  .subArea span {
    font-size: 2.5rem;
  }
}

.blog_subArea {
  color: #fff;
  text-align: center;
  font-size: 5.2rem;
  font-weight: bold;
  padding: 90px 0;
  line-height: 1.2em;
  background-image: url(../images/home/service_bg.jpg);
  background-size: cover;
}

@media screen and (max-width: 834px) {
  .blog_subArea {
    font-size: 3rem;
    padding: 60px 5%;
  }
}

.pankuzu {
  margin-top: 12px;
  margin-bottom: 4.8rem;
  font-size: 1.4rem;
  display: flex;
  color: #222;
}

.pankuzu ul div::before {
  content: "\f105";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  padding: 0 10px;
}

.pankuzu ul li:last-child a {
  pointer-events: none;
  color: #21120e;
  text-decoration: none;
}

footer#global_footer {
  font-size: 14px;
  padding: 100px 0;
}

footer#global_footer .footer {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  footer#global_footer .footer {
    padding-left: 24px;
    padding-right: 24px;
    display: block;
  }
}

footer#global_footer .ft_L {
  text-align: left;
}

@media screen and (max-width: 640px) {
  footer#global_footer .ft_L {
    text-align: center;
  }
}

footer#global_footer .ft_L .c_name {
  font-size: 1.25em;
  font-weight: bold;
}

footer#global_footer .ft_logo {
  margin-bottom: 45px;
}

footer#global_footer .ft_R {
  display: flex;
}

@media screen and (max-width: 640px) {
  footer#global_footer .ft_R {
    display: block;
    margin-top: 30px;
  }
}

footer#global_footer .ft_nav {
  margin-bottom: 35px;
}

@media screen and (max-width: 640px) {
  footer#global_footer .ft_nav {
    margin-bottom: 0;
  }
}

footer#global_footer .ft_nav ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 640px) {
  footer#global_footer .ft_nav ul {
    display: block;
  }
}

@media screen and (max-width: 640px) {
  footer#global_footer .ft_nav li {
    width: 100%;
  }
}

footer#global_footer .ft_nav li a {
  text-align: left;
  padding: 10px 15px;
  display: block;
  color: #222;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  footer#global_footer .ft_nav li a {
    background: #a1b41a;
    border-radius: 50px;
    margin-bottom: 2%;
    color: #fff;
    text-align: center;
  }
}

footer#global_footer .copy_bg {
  text-align: right;
  padding-top: 40px;
  font-family: 'Montserrat', sans-serif;
}

@media screen and (max-width: 640px) {
  footer#global_footer .copy_bg {
    text-align: center;
  }
}

footer#global_footer #copy {
  color: #cccccc !important;
}

footer#global_footer #copy a {
  text-decoration: none;
  color: #cccccc !important;
}

footer#global_footer .blog_copy #copy {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.title_01 h2 {
  font-size: clamp(40px, 6vw, 58px);
  line-height: 1.5em;
  font-weight: bold;
  position: relative;
  display: inline-block;
}

.title_01 h2:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 11%;
  height: 3px;
  background-color: #a1b41a;
}

.title_01 h2:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 89%;
  height: 3px;
  background-color: #e1e1e1;
}

.title_02 h2 {
  font-size: clamp(40px, 6vw, 58px);
  line-height: 1.5em;
  font-weight: bold;
  position: relative;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  color: #fff;
}

.title_02 h2 .deco {
  font-size: 18px;
  display: block;
  line-height: 1em;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.title_02 h2:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 11%;
  height: 3px;
  background-color: #a1b41a;
}

.title_02 h2:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 89%;
  height: 3px;
  background-color: #e1e1e1;
}

.title_03 h2 {
  font-size: clamp(40px, 6vw, 58px);
  line-height: 1.5em;
  font-weight: bold;
  position: relative;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
}

.title_03 h2 .deco {
  font-size: 18px;
  display: block;
  line-height: 1em;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.title_03 h2:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 11%;
  height: 3px;
  background-color: #a1b41a;
}

.title_03 h2:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 89%;
  height: 3px;
  background-color: #e1e1e1;
}

.text div {
  line-height: 2.25em;
}

.cont_01 {
  position: relative;
}

.cont_01 .flxC {
  position: absolute;
  left: -20%;
  bottom: 0;
}

@media screen and (max-width: 640px) {
  .cont_01 .flxC {
    left: -70%;
  }
}

.cont_01 .inner2 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media screen and (max-width: 834px) {
  .cont_01 .inner2 {
    display: block;
  }
}

.cont_01 .inner2 .flxL {
  width: 48%;
}

.cont_01 .inner2 .flxL img {
  width: 100% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .cont_01 .inner2 .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .cont_01 .inner2 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.cont_01 .inner2 .flxR {
  width: 48%;
}

@media screen and (max-width: 834px) {
  .cont_01 .inner2 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.cont_01 .inner2 .flxL img {
  border-radius: 80px;
}

.parallax img {
  transition: 2s;
}

.cont_02 {
  background-image: url(../images/home/service_bg.jpg);
  background-size: cover;
  padding: 80px 0 50px;
}

@media only screen and (min-width: 834px) and (max-width: 1200px) {
  .cont_02 {
    padding: 80px 24px 50px;
  }
}

@media screen and (max-width: 834px) {
  .cont_02 {
    padding: 80px 24px 50px;
  }
}

.cont_03 {
  background-image: url(../images/home/contact_bg.jpg);
  background-size: cover;
  padding: 120px 0;
}

.btnbox2 {
  justify-content: center;
}

.btnbox2 .box {
  margin-right: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .btnbox2 .box {
    margin-bottom: 5%;
  }
  .btnbox2 .box:nth-of-type(3) {
    margin-right: 0;
  }
}

@media screen and (max-width: 640px) {
  .btnbox2 .box {
    margin-right: 0;
    margin-bottom: 5%;
    width: 100%;
  }
}

.btnbox2 .box a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnbox2 .box:nth-of-type(1) a {
  color: #fff;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25em;
  background-color: #2eb5ce;
  font-family: 'Montserrat', sans-serif;
  width: 238px;
}

@media screen and (max-width: 640px) {
  .btnbox2 .box:nth-of-type(1) a {
    width: 100%;
  }
}

.btnbox2 .box:nth-of-type(2) a {
  color: #fff;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25em;
  background-color: #a1b41a;
  font-weight: bold;
  width: 238px;
}

@media screen and (max-width: 640px) {
  .btnbox2 .box:nth-of-type(2) a {
    width: 100%;
  }
}

.btnbox2 .box:nth-of-type(3) {
  margin-right: 0;
}

.btnbox2 .box:nth-of-type(3) a {
  color: #fff;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25em;
  background-color: #00b900;
  font-family: 'Montserrat', sans-serif;
  width: 238px;
}

@media screen and (max-width: 640px) {
  .btnbox2 .box:nth-of-type(3) a {
    width: 100%;
  }
}

.btnbox2 .box img {
  margin-right: 10px;
}

.flexbox_01 {
  justify-content: space-between;
}

.flexbox_01 .box {
  width: 22%;
}

.flexbox_01 .box article > div {
  text-align: center;
  font-weight: bold;
  color: #fff;
  margin-top: 20px;
}

@media screen and (max-width: 834px) {
  .flexbox_01 .box {
    width: 48%;
    margin-bottom: 20px;
  }
}

.flexbox_01 .box img {
  width: 100% !important;
}

.box_wrap {
  background-color: #2eb5ce;
  padding: 50px 0 80px;
}

.flexbox_02 {
  justify-content: center;
}

.flexbox_02 .box {
  width: 22%;
}

.flexbox_02 .box:first-of-type {
  margin-right: 4%;
}

.flexbox_02 .box article > div {
  text-align: center;
  font-weight: bold;
  color: #fff;
  margin-top: 20px;
}

@media screen and (max-width: 834px) {
  .flexbox_02 .box {
    width: 48%;
    margin-bottom: 20px;
  }
}

.flexbox_02 .box img {
  width: 100% !important;
}

div#form_calendar table tbody tr td.close {
  background: #d5ea3b66 !important;
}

div#form_calendar table tbody tr td.open {
  background: #2eb5ce57 !important;
}

div#form_calendar dl.open label {
  background: #2eb5ce57 !important;
}

div#form_calendar dl.open {
  align-items: center;
}

div#form_calendar dl.open dt {
  display: flex;
  align-items: center;
}

a:visited {
  color: #a1b41a;
}

section div#contents article div.date {
  font-family: 'Montserrat', sans-serif;
  color: #e1e1e1;
}

section div#contents article h1 {
  font-weight: bold;
  position: relative;
  margin-bottom: 40px;
  padding-bottom: 15px !important;
}

section div#contents article h1:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 11%;
  height: 3px;
  background-color: #a1b41a;
}

section div#contents article h1:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 89%;
  height: 3px;
  background-color: #e1e1e1;
}

div.blog_list ul {
  display: block !important;
  margin-top: auto;
}

div.blog_list ul li a {
  color: #fff;
  background-color: #a1b41a;
  padding: 10px;
  border-radius: 10px;
  line-height: 1em;
}

.blog_text:is(div) > ul {
  margin-top: auto;
}

.blog_cont {
  max-height: 450px;
  overflow-y: auto;
}

.blog_cont .blog_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row !important;
  border: none !important;
}

.blog_cont .blog_list > div {
  width: 48%;
  border-bottom: 1px solid #ccc !important;
}

@media screen and (max-width: 834px) {
  .blog_cont .blog_list > div {
    flex-direction: row !important;
  }
}

@media screen and (max-width: 640px) {
  .blog_cont .blog_list > div {
    width: 100%;
  }
}

.blog_cont .blog_list > div .blog_photo {
  width: 40% !important;
  padding: 20px 0 !important;
}

.blog_cont .blog_list > div .blog_text {
  width: 60% !important;
  font-size: 14px !important;
}

.blog_cont .blog_list > div .blog_text .detail {
  display: none;
  margin-top: 0 !important;
}

.blog_cont .blog_list > div .blog_text h3 {
  font-size: 18px;
}

.blog_cont .blog_list > div .blog_text h3 a {
  color: #222;
  font-weight: bold;
}

.blog_cont .blog_list > div .blog_text .blog_date {
  font-family: 'Montserrat', sans-serif;
  color: #595959 !important;
}

.btn a {
  display: block;
  max-width: 210px;
  color: #fff;
  padding: 30px;
  width: 100%;
  text-align: center;
  text-decoration: none;
}

@media screen and (max-width: 640px) {
  .btn a {
    margin-right: auto;
    margin-left: auto;
  }
}

.btn a {
  background-color: #a1b41a;
  color: #fff !important;
  margin: auto;
  position: relative;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  border-radius: 50px;
  padding: 20px;
  font-size: 1.125em;
}

.btn a:before {
  content: "→";
  position: absolute;
  right: 5%;
  top: auto;
  font-size: 22px;
}

@media screen and (max-width: 834px) {
  .btn a {
    max-width: 80% !important;
  }
}

.btnbox {
  justify-content: space-between;
}

.btnbox .box {
  width: 30%;
  position: relative;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .btnbox .box {
    width: 48%;
  }
}

@media screen and (max-width: 640px) {
  .btnbox .box {
    width: 100%;
  }
}

.btnbox .box:before {
  content: "→";
  position: absolute;
  right: 5%;
  top: 50%;
  font-size: 50px;
  transform: translateY(-50%);
}

.btnbox .box a {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 2em;
  color: #222;
  display: block;
  line-height: 1.2em;
  padding: 30px 0;
}

.btnbox .box a span {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 14px;
  display: block;
}

.btnbox .box a:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 11%;
  height: 3px;
  background-color: #a1b41a;
}

.btnbox .box a:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 89%;
  height: 3px;
  background-color: #e1e1e1;
}

.btnbox .box a:hover {
  background-color: #a1b41a;
  color: #fff;
}

.btnbox .box a:hover:before {
  width: 100%;
  transition: 1s;
}

.btnbox .box a:hover:after {
  width: 0;
  transition: 1s;
}

.insta_wrap {
  position: relative;
}

.insta_wrap .parallax {
  position: absolute;
  left: -90%;
  top: 0;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: none;
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 0 2px #ccc;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  box-shadow: none;
}

div.items > article a {
  color: #111;
  text-decoration: none !important;
  font-size: 16px;
}

div.under ul li a {
  font-weight: 400;
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  opacity: 1;
  border: 1px solid #333;
  color: #333 !important;
  padding: 10px;
}

div.under ul li a:hover {
  border: 1px solid #b0ddd9;
  background: #b0ddd9;
  color: #fff !important;
}

div.select > select {
  height: 100%;
}

div.search form input {
  padding: 10px 37px 10px 5px;
}

div.order ul li a {
  color: #111;
}

div.items > article p.price {
  color: red;
}

div.crumb {
  display: none;
}

div.item_view div.item_option {
  width: 100% !important;
}

div.item_option dl, button.addcart {
  width: 50% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  div.item_option dl, button.addcart {
    margin: 0 auto;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .addcart {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

div.item_view div.struct {
  display: block;
}

div.photo div.large {
  width: auto;
}

div.photo {
  margin-left: 0;
}

h2.title_h2 {
  background: none !important;
  border-top: none !important;
  border-bottom: 1px solid #CCCCCC;
  font-size: 20px !important;
  line-height: 1.3;
  margin: 65px 0 20px !important;
  padding: 10px !important;
}

h2.title_h2 span {
  border: none !important;
  padding: 0 !important;
}

ul.item_list li {
  margin-bottom: 10px !important;
}

ul.item_list li h3.title_h3 {
  font-size: 18px !important;
}

.btn_cart a {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #000000 !important;
  background-size: 30px auto !important;
  color: #FFFFFF !important;
  display: block !important;
  padding: 10px 10px 10px 50px !important;
  width: 100% !important;
  border: 1px solid #ffffff;
}

.btn_cart a:hover {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #CCCCCC !important;
  background-size: 30px auto !important;
}

.sort_catalog {
  overflow: hidden;
}

.sort_catalog ul {
  display: flex;
}

.item_title, .wishlist {
  margin: 0 !important;
}

.shop_cont h2.title_h2, .item_info h2.title_h2 {
  border-top: 2px solid #CCCCCC;
}

.shop_cont a, .item_info a {
  color: #CCCCCC;
}

.shop_cont a:hover, .item_info a:hover {
  text-decoration: none;
}

.item_title h2 {
  border-top: 2px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
}

.shop_cont table {
  table-layout: auto;
}

.flex1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex1._top {
  align-items: flex-start;
}

.flex1._center {
  align-items: center;
}

.flex1:before {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 1;
}

.flex1:before {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 1;
}

.flex1:after {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 2;
}

.flex1 .box {
  width: calc(100% / 1 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex1 .box img {
  width: 100% !important;
}

.flex2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex2._top {
  align-items: flex-start;
}

.flex2._center {
  align-items: center;
}

.flex2:before {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 1;
}

.flex2:before {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 1;
}

.flex2:after {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 2;
}

.flex2 .box {
  width: calc(100% / 2 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex2 .box img {
  width: 100% !important;
}

.flex3 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex3._top {
  align-items: flex-start;
}

.flex3._center {
  align-items: center;
}

.flex3:before {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 1;
}

.flex3:before {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 1;
}

.flex3:after {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 2;
}

.flex3 .box {
  width: calc(100% / 3 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex3 .box img {
  width: 100% !important;
}

.flex4 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex4._top {
  align-items: flex-start;
}

.flex4._center {
  align-items: center;
}

.flex4:before {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 1;
}

.flex4:before {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 1;
}

.flex4:after {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 2;
}

.flex4 .box {
  width: calc(100% / 4 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex4 .box img {
  width: 100% !important;
}

.square._100 .square-inner:before {
  padding-top: 100%;
}

.square._75 .square-inner:before {
  padding-top: 75%;
}

.square._66 .square-inner:before {
  padding-top: 66%;
}

.square td .square-inner {
  display: block;
  position: relative;
}

.square td .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square td .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.square > .square-inner {
  display: block;
  position: relative;
}

.square > .square-inner .itext {
  float: none !important;
  margin-right: 0 !important;
}

.square > .square-inner .itext img {
  float: none !important;
  margin-right: 0 !important;
}

.square > .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square > .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.square > .box .itext {
  float: none !important;
  margin-right: 0 !important;
}

.square > .box .itext img {
  float: none !important;
  margin-right: 0 !important;
}

.square > .box .square-inner {
  display: block;
  position: relative;
}

.square > .box .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square > .box .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.bg100 {
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
}

.flex2cl_01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 {
    flex-direction: column-reverse;
  }
}

.flex2cl_01 .flxL {
  width: 57%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 .flxL {
    width: 100%;
  }
}

.flex2cl_01 .flxR {
  width: 40%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 .flxR {
    width: 100%;
    margin-bottom: 20px;
  }
}

.flex2cl_01 .flxR .img {
  width: 100%;
}

.flex2cl_01 .flxR .img img {
  width: 100% !important;
}

.flex2cl_02 {
  border: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -4%;
  margin-bottom: 50px;
}

@media screen and (max-width: 1200px) {
  .flex2cl_02 {
    margin-left: -2.4390%;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_02 {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex2cl_02 .box {
  position: relative;
  width: 46%;
  margin-left: 4%;
  margin-bottom: 20px;
}

@media screen and (max-width: 1200px) {
  .flex2cl_02 .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_02 .box {
    width: 100%;
    margin-left: 0;
  }
}

.flex2cl_02 .box img {
  width: 100% !important;
}

.flex2cl_02 .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex2cl_02 .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex3cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.43902%;
}

@media screen and (max-width: 1200px) {
  .flex3cl {
    margin-left: -2.4390%;
  }
}

@media screen and (max-width: 640px) {
  .flex3cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex3cl .box {
  position: relative;
  width: 30.89431%;
  margin-left: 2.43902%;
  margin-bottom: 30px;
}

@media screen and (max-width: 1200px) {
  .flex3cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 640px) {
  .flex3cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex3cl .box img {
  width: 100% !important;
}

.flex3cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex3cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex4cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -3.22581%;
}

@media screen and (max-width: 1200px) {
  .flex4cl {
    margin-left: -2.4390%;
  }
}

@media screen and (max-width: 640px) {
  .flex4cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex4cl .box {
  position: relative;
  width: 21.77419%;
  margin-left: 3.22581%;
  margin-bottom: 30px;
}

@media screen and (max-width: 1200px) {
  .flex4cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 640px) {
  .flex4cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex4cl .box img {
  width: 100% !important;
}

.flex4cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex4cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.local_title_01 h2 {
  font-size: clamp(30px, 4vw, 40px);
  line-height: 1.2em;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
}

.local_title_01 h2 .deco {
  color: #a1b41a;
  font-size: 50px;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.2em;
}

@media screen and (max-width: 834px) {
  .local_title_01 h2 .deco {
    display: block;
  }
}

.local_title_01 h2 .deco .num {
  border-radius: 100%;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  background-color: #2eb5ce;
  width: 60px;
  height: fit-content;
  display: inline-block;
  text-align: center;
  font-size: 40px;
  vertical-align: bottom;
  margin-left: 10px;
  margin-right: 10px;
}

.sub_title_01 h3 {
  font-size: 2em;
  line-height: 1.2em;
  font-weight: bold;
  position: relative;
  padding: 0 0 1rem 3.5rem;
  border-bottom: 1px solid #e1e1e1;
}

.sub_title_01 h3:before {
  content: "●";
  color: #2eb5ce;
  position: absolute;
  left: 0;
  top: auto;
}

@media screen and (max-width: 834px) {
  .sub_title_01 h3 {
    font-size: 1.5em;
    padding: 0 0 1rem 2.5rem;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .img .imgL {
    max-width: 100% !important;
  }
}

.pc_only {
  display: block;
}

@media screen and (max-width: 640px) {
  .pc_only {
    display: none;
  }
}

.local_cont_01 .inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 834px) {
  .local_cont_01 .inner {
    display: block;
  }
}

.local_cont_01 .inner .flxL {
  width: 48%;
}

.local_cont_01 .inner .flxL img {
  width: 100% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .local_cont_01 .inner .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .local_cont_01 .inner .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.local_cont_01 .inner .flxR {
  width: 48%;
}

@media screen and (max-width: 834px) {
  .local_cont_01 .inner .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.local_cont_01 .inner .img img {
  border-radius: 30px;
}

.local_cont_02 .inner {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}

@media screen and (max-width: 834px) {
  .local_cont_02 .inner {
    display: block;
  }
}

.local_cont_02 .inner .flxL {
  width: 48%;
}

.local_cont_02 .inner .flxL img {
  width: 100% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .local_cont_02 .inner .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .local_cont_02 .inner .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.local_cont_02 .inner .flxR {
  width: 48%;
}

@media screen and (max-width: 834px) {
  .local_cont_02 .inner .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.local_cont_02 .inner .img img {
  border-radius: 30px;
}

.local_cont_03 {
  position: relative;
  --cir: 80px;
  padding-left: calc(var(--cir) * 1.5);
}

.local_cont_03:before {
  content: "";
  position: absolute;
  left: 1rem;
  top: auto;
  height: 100%;
  width: 4px;
  background-color: #e1e1e1;
}

@media screen and (max-width: 834px) {
  .local_cont_03 {
    --cir: 40px
	;
  }
}

.ba_cont {
  justify-content: space-between;
  align-items: center;
}

.ba_cont .box {
  text-align: center;
}

.ba_cont .box img {
  border-radius: 30px;
}

.ba_cont .box article > div {
  font-size: 1.5em;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

.ba_cont .box:first-of-type, .ba_cont .box:nth-of-type(3) {
  width: 40%;
}

.arrow {
  display: inline-block;
  vertical-align: middle;
  color: #2eb5ce;
  line-height: 1;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: transparent;
  border-width: 2em 3em;
  border-left-color: currentColor;
  border-right: 0;
}

.local_blog .blog_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row !important;
  border: none !important;
}

.local_blog .blog_list > div {
  width: 48%;
  border: 1px solid #ccc !important;
  margin-bottom: 3rem;
}

@media screen and (max-width: 834px) {
  .local_blog .blog_list > div {
    flex-direction: row !important;
  }
}

@media screen and (max-width: 640px) {
  .local_blog .blog_list > div {
    width: 100%;
    display: block !important;
    padding: 15px;
  }
}

.local_blog .blog_list > div .blog_photo {
  width: 40% !important;
  padding: 15px !important;
}

@media screen and (max-width: 640px) {
  .local_blog .blog_list > div .blog_photo {
    width: 100% !important;
  }
}

.local_blog .blog_list > div .blog_photo img {
  height: fit-content !important;
  width: 100% !important;
  object-fit: cover;
  font-family: "object-fit: cover;";
}

.local_blog .blog_list > div .blog_text {
  width: 60% !important;
  font-size: 14px !important;
}

@media screen and (max-width: 640px) {
  .local_blog .blog_list > div .blog_text {
    width: 100% !important;
  }
}

.local_blog .blog_list > div .blog_text .clearfix {
  display: none !important;
}

.local_blog .blog_list > div .blog_text h3 {
  font-size: 18px;
}

.local_blog .blog_list > div .blog_text h3 a {
  color: #222;
  font-weight: bold;
}

.local_blog .blog_list > div .blog_text .blog_date {
  font-family: 'Montserrat', sans-serif;
  color: #595959 !important;
}

.shaze_big div h2 {
  font-weight: bold;
  font-size: 2.4em;
  line-height: 1.25em;
}

.shaze_big div h2 .decoL {
  color: #a1b41a;
  font-size: 50px;
  padding-right: 15px;
}

.shaze_big div h2 .decoR {
  color: #a1b41a;
  font-size: 50px;
  padding-left: 15px;
}

.shaze_big div .deco2 {
  font-weight: bold;
  font-size: 24px;
  line-height: 1.25em;
}

.shaze {
  width: fit-content;
  margin: auto;
  font-size: 1.25em;
}

.shaze > div {
  line-height: 2.25em;
}

/* fadeUp */
.fadeUp {
  animation-name: fadeUpAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeUpAnime {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.fadeUpTrigger {
  opacity: 0;
}

.dl_01 dt {
  border: none !important;
  border-bottom: 1px solid #a1b41a !important;
  padding: 15px !important;
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .dl_01 dt {
    display: block !important;
    width: 100% !important;
  }
}

.dl_01 dd {
  border: none !important;
  border-bottom: 1px solid #ccc !important;
  padding: 15px !important;
}

@media screen and (max-width: 640px) {
  .dl_01 dd {
    display: block !important;
    width: 100% !important;
  }
}

@media screen and (max-width: 640px) {
  .dl_01 dl {
    display: block !important;
  }
}

.form dt {
  border: none !important;
}

.form dd {
  border: none !important;
}

.form dl {
  border-bottom: 1px solid #ccc !important;
  padding: 15px 0;
}

.map iframe {
  width: 100%;
}

.policy_cont h2 {
  font-size: 1.4em;
  border-bottom: 1px solid #2eb5ce;
  padding-bottom: 15px;
  font-weight: bold;
  margin: 50px 0 20px 0;
}

.mail {
  display: none;
}

.confirm {
  margin-top: 50px;
}

.policy {
  padding: 10px 0;
  text-align: center;
}
